export const STATUS_NAMES = {
    "offline": "Không hoạt động",
    "online": "Đang hoạt động",
    "away": "Đi vắng",
    "busy": "Đang bận"
}
export const INTERNAL_REQUEST_DEFAULT = {
    type: null,
    basket_code: null,
    cell_id: null,
    money: null,
    note: null,
    order_tracking_id: null,
    quantity: null,
    receipt_tracking_id: null,
    sku: null,
    working_shift: null,
    working_date: null,
}

export const INTERNAL_PICKUP_REDUCE = 3
export const INTERNAL_RETURN_HAS_MONEY = 4
export const INTERNAL_REQUEST_BONUS = 16
export const INTERNAL_REQUEST_PUNISHMENT = 17
export const ALLOW_REQUEST_CREATE_TYPES = [
    10, 11, 12, 16, 17, 19, 21,
    99
]

export const RETURN_RECEIVE_REASONS = [
    {
        value: 'Mất / Thiếu hàng',
        text: 'Mất / Thiếu hàng'
    },
    {
        value: 'P.Bì bị rạch mở, lộ mã kích hoạt',
        text: 'P.Bì bị rạch mở, lộ mã kích hoạt'
    },
    {
        value: 'Hàng bị ướt',
        text: 'Hàng bị ướt'
    },
    {
        value: 'Hàng bị vỡ / hỏng',
        text: 'Hàng bị vỡ / hỏng'
    },
    {
        value: 'Hàng bị tráo / đổi',
        text: 'Hàng bị tráo / đổi'
    },
    {
        value: 'VC. Trả nhầm đơn',
        text: 'VC. Trả nhầm đơn'
    },
    {
        value: 'Thừa hàng',
        text: 'Thừa hàng'
    },
    {
        value: 'Khác',
        text: 'Khác'
    },
]

export const RETURN_IMPORT_REASONS = [
    {
        value: 'HỦY HÀNG - Đối tác yêu cầu',
        text: 'HỦY HÀNG - Đối tác yêu cầu'
    },
    {
        value: 'TRẢ HÀNG - Đối tác yêu cầu',
        text: 'TRẢ HÀNG - Đối tác yêu cầu'
    },
    {
        value: 'TRẢ HÀNG - Hình thức không đạt',
        text: 'TRẢ HÀNG - Hình thức không đạt'
    },
    {
        value: 'TRẢ HÀNG - Bao bì hư hại',
        text: 'TRẢ HÀNG - Bao bì hư hại'
    },
    {
        value: 'TRẢ HÀNG - Hàng hóa hư hại',
        text: 'TRẢ HÀNG - Hàng hóa hư hại'
    },
    {
        value: 'Không có hàng',
        text: 'Không có hàng'
    },
    {
        value: 'Giao một phần',
        text: 'Giao một phần'
    },
    {
        value: 'Hết hạn sử dụng',
        text: 'Hết hạn sử dụng'
    },
    {
        value: 'Khác',
        text: 'Khác'
    },
]

export const GOODS_ISSUE_STATE = [
    {
        text: 'Hủy đơn',
        value: 0
    },
    {
        text: 'Thiếu hàng',
        value: 20
    },
    {
        text: 'Chờ mã vận chuyển',
        value: 35
    },
    {
        text: 'Đã tiếp nhận',
        value: 40
    },
    {
        text: 'Đang xử lý',
        value: 60
    },
    {
        text: 'Đã pick',
        value: 100
    },
    {
        text: 'Đã đóng gói',
        value: 160
    },
    {
        text: 'Đã bàn giao vận chuyển',
        value: 200
    },
    {
        text: 'Đã nhận hoàn',
        value: 220
    },
    {
        text: 'Đã nhập hoàn',
        value: 240
    },
]

export const PICKUP_COMMAND_STATUS = [
    {
        text: 'Chưa Pick',
        value: 0
    },
    {
        text: 'Đã Pick',
        value: 1
    },
]

export const PICKUP_COMMAND_PROCESS_TYPE = [
    {
        text: '1',
        value: 1
    },
    {
        text: '2',
        value: 2
    },
    {
        text: '3',
        value: 3
    },
]

export const PICKUP_COMMAND_TYPE = [
    {
        text: '01 sp',
        value: 0
    },
    {
        text: '≤32 cm || ≥ 02 sp || 01 sku',
        value: 1
    },
    {
        text: '≤32 cm || ≥ 02 sp || 02 sku',
        value: 2
    },
    {
        text: '≤32 cm || ≤ 20 sp || 03 sku (↑)',
        value: 3
    },
    {
        text: '>32 cm || ≥ 02 sp || 01 sku',
        value: 4
    },
    {
        text: '>32 cm || ≥ 02 sp || 02 sku',
        value: 5
    },
    {
        text: '>32 cm || ≤ 20 sp || 03 sku (↑)',
        value: 6
    },
    {
        text: '> 20 sp',
        value: 100
    },
    {
        text: 'Tracking ID',
        value: 101
    },
]

export const DELIVERY_STATE = [
    {
        text: 'Chờ tiếp nhận',
        value: 50
    },
    {
        text: 'Đang nhận',
        value: 55
    },
    {
        text: 'Đã lấy hàng',
        value: 100
    },
    {
        text: 'Đang giao',
        value: 200
    },
    {
        text: 'Chưa giao được',
        value: 225
    },
    {
        text: 'Đã giao hàng - Chờ đối soát',
        value: 250
    },
    {
        text: 'Không giao được - Chờ hoàn',
        value: 275
    },
    {
        text: 'Thành công',
        value: 400
    },
    {
        text: 'Thành công có hoàn',
        value: 401
    },
    {
        text: 'Đơn hoàn',
        value: 402
    },
    {
        text: 'Hủy đơn',
        value: 700
    },
    {
        text: 'Bồi thường',
        value: 800
    },
    {
        text: 'Tự vận chuyển',
        value: 999
    },
]

export const STAMP_STATUS = [
    {
        text: 'Không cần',
        value: 1
    },
    {
        text: 'Chưa có',
        value: 2
    },
    {
        text: 'Đã có',
        value: 3
    },
]

export const IMAGE_OPTIONS = [
    {
        text: 'Có ',
        value: 'y'
    },
    {
        text: 'Không',
        value: 'n'
    },
]

export const TRANSPORT_OPTIONS = [
    {
        text: 'Road',
        value: 'road'
    },
    {
        text: 'Fly',
        value: 'fly'
    },
]

export const YES_NO_OPTIONS = [
    {
        text: 'Yes',
        value: 1
    },
    {
        text: 'No',
        value: 0
    },
]

export const STATUS_OPTIONS = [
    {
        text: 'Active',
        value: 1,
        color: 'success--text',
    },
    {
        text: 'Inactive',
        value: 0,
        color: 'error--text',
    },
]

export const GOODS_RETURN_SEAL_STATUS = [
    {
        text: 'Còn niêm phong',
        value: 1
    },
    {
        text: 'Mất niêm phong',
        value: 0
    },
]

export const WORKING_TIMES = [
    {
        text: 'Sáng',
        value: 1
    },
    {
        text: 'Chiều',
        value: 2
    },
    {
        text: 'Tối',
        value: 3
    },
    {
        text: 'Ngoài giờ',
        value: 0
    },
]

export const SOURCES = [
    {
        text: 'SSC',
        value: -2
    },
    {
        text: 'TVC',
        value: -1
    },
    {
        text: 'Shopee',
        value: 1
    },
    {
        text: 'Lazada',
        value: 2
    },
    {
        text: 'Tiktok',
        value: 5
    },
]

export const E_MARKET_SHOPEE = 1
export const E_MARKET_LAZADA = 2
export const E_MARKET_TIKTOK = 5

export const BARCODE_MAT_NIEM_PHONG = 'SSC-MNP-SSC'
export const BARCODE_REMOVE = 'SSC-RMRT-SSC'
export const BARCODE_HU_HAI = 'SSC-HUHAI-SSC'
export const BARCODE_REMOVE_HANDOVER = 'SSC-RMHO-SSC'
export const BARCODE_REMOVE_RECEIPT = 'SSC-RMRC-SSC'
export const BARCODE_FINISH_PACKING_1SKU = 'SSC-F1SKU-SSC'
export const BARCODE_PICKUP_LATER = '88SSC98'

export const POS_TABLE_PACKING = 12

export const ACTIVITIES_LOGS = {
    ACTION: {
        RECEIPT: 1, // nhap kho
        RECEIPT_STOWING: 2, // len ke nhap kho
        PICKUP: 3, // pickup
        PACKING: 4, // dong goi
        HANDOVER: 5, // ban giao van chuyen
        RETURN_RECEIVE: 6, // nhan hoan
        RETURN_IMPORT: 7, // nhap hoan
        RETURN_STOWING: 8, // len ke hang hoan
        RECOUP: 9, // boi thuong,
    },
    JOB: {
        ITEM: 1, // item
        ORDER: 2, // order
        FAIL_EMPLOYEE: 3, // fail by employee
        FAIL_SSC: 4, // fail by ssc,
        LOG: 99,
    },
    WORKING_SHIFT: {
        SA: 1, // ca sang
        CH: 2 // ca chieu
    },
}

export const CUSTOMER_PERMISSIONS = [
    'goods_receipt_create', // ky gui hang hoa
    'goods_receipt_history', // lich su nhap kho
    'goods_return_history', // lich su hang hoan
    'order_create_single', // tao don le
    'order_create_excel', // tao don excel
    'order_create_e_market', // tao don san
    'order_create_select_discount', // tao don chon km
    'goods_manager', // qly hang hoa
    'goods_category_price', // qly gia hang hoa
    'goods_reserved', // giu ton
    'order_manager', // qly don hang
    'order_cancel_and_change', // huy - doi don hang
    'request_manger', // yeu cau xu ly
]

export const CUSTOMER_POS_PERMISSIONS = [
    'pos_timekeeping',
    'pos_goods_transfer', // chuyen hang noi bo
    'pos_goods_manager', // qly hang hoa
    'pos_goods_uid_manager', // qly uid
    'pos_goods_receipt', // nhap pos
    'pos_goods_set_position', // len ke
    'pos_goods_pickup', // pickup
    'pos_goods_set_action', // cbi hang
    'pos_accrue_point_config', // qly tich diem
    'pos_voucher_config', // qly voucher
    'pos_promotion_config', // qly km
    'pos_order_create', // ban hang
    'pos_order_list', // don hang
    'pos_goods_display_request', // trung bay
    'pos_order_online_list', // don online -list
    'pos_order_online_pickup', // don online -pickup
    'pos_order_online_packing', // don online - dong goi
    'pos_order_online_handover', // don online - ban giao
    'pos_order_online_return_receive', // don online - nhan hoan
    'pos_order_online_return_import', // don online - nhap hoan
]

export const NUMBER_TO_TEXT = {
    0: "ZPK",
    1: "YNJ",
    2: "XMH",
    3: "WKG",
    4: "VJF",
    5: "UHE",
    6: "TGD",
    7: "SFC",
    8: "REB",
    9: "QDA"
};

export const NOTIFY_TYPE_OPTIONS = [12, 13, 15, 16, 17, 98]
export const NOTIFY_STATUS_OPTIONS = [0, 1]
export const SUPPLIER_TYPE_OPTIONS = [0, 1, 2]
export const PROFILE_TYPE_OPTIONS = [1, 2, 3]

export const ID_DELIVERY_GHN = 3
export const ID_DELIVERY_GHTK = 4
export const ID_DELIVERY_VNP = 33
export const ID_DELIVERY_JNT = 36
export const ID_DELIVERY_SPX = 74
export const ID_DELIVERY_VTP = 1
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHTK = ['token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_GHN = ['token', 'shop_id']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_SPX = ['app_id', 'app_secret', 'user_id', 'user_secret']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_JNT = ['company_id', 'customer_id', 'token']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_VNP = ['username', 'password']
export const ALLOW_FIELD_DELIVERY_ACCOUNT_VTP = ['username', 'password', 'shop_id', 'service']

export const DEFAULT_SSC_DELIVERY_CONTRACTS = [-1, 7]

export const SSC_DELIVERY_ID = -1
export const TVC_DELIVERY_ID = -2
export const OPTIMIZE_DELIVERY_ID = -3
export const BY_WEIGHT_DELIVERY_ID = -4
export const DEFAULT_CONTRACT_IDS = [SSC_DELIVERY_ID, TVC_DELIVERY_ID, BY_WEIGHT_DELIVERY_ID]
export const DELIVERY_TYPE_PROVINCIAL = 1
export const DELIVERY_TYPE_INTER_PROVINCIAL = 2

export const NOTIFY_TYPE_COLOR = {
    1: 'purple lighten-3',
    2: 'error',
    3: 'warning',
    4: 'error',
    11: 'yellow lighten-2',
    12: 'amber lighten-2',
    13: 'orange lighten-2',
    14: 'deep-orange lighten-2',
    15: 'brown lighten-2',
    16: 'blue-grey lighten-2',
    17: 'warning',
    98: 'info',
    99: 'teal lighten-3',
}

export const RECEIPT_DETAIL_STATUS_OPTIONS = [
    {
        text: 'Chưa kiểm',
        value: 'ny'
    },
    {
        text: 'Đang kiểm',
        value: 'd'
    },
    {
        text: 'Đã kiểm',
        value: 'y'
    },
]

export const POS_ORDER_TYPES = [
    {
        text: 'Bán hàng',
        value: 1
    },
    {
        text: 'Đổi trả',
        value: 2
    },
]

export const GOODS_UID_SOURCE_TYPES = [
    {
        text: 'Kho',
        value: 1
    },
    {
        text: 'Pos',
        value: 2
    },
]

export const GOODS_UID_STATUS_OPTIONS = [
    {
        text: 'Tạo',
        value: 1
    },
    {
        text: 'Kiểm đếm',
        value: 2
    },
    {
        text: 'Lên kệ',
        value: 3
    },
    {
        text: 'Pickup',
        value: 4
    },
    {
        text: 'Đóng gói',
        value: 5
    },
    {
        text: 'Điều chuyển',
        value: 6
    },
    {
        text: 'Xuất hàng lỗi',
        value: 7
    },
    {
        text: 'Thu hồi',
        value: 0
    },
]

export const BUYER_HIDDEN_INFO_CODE = 'SSC-HIDDENINF-SSC'
export const REMOVE_ITEM_CODE = 'SSC-POSREMOVE-SSC'
export const GENDER_MALE_CODE = 'SSC-MALE-SSC'
export const GENDER_FEMALE_CODE = 'SSC-FEMALE-SSC'

export const POS_ORDER_STEP = {
    PHONE: 1,
    NAME: 2,
    GENDER: 3,
    BIRTH_DAY: 4,
    BIRTH_MONTH: 5,
    BIRTH_YEAR: 6,
    UID_PACKAGE: 7,
}

export const POS_ORDER_BUYER_STEP_KEYS = {
    1: 'phone',
    2: 'name',
    3: 'gender',
    4: 'birthday_day',
    5: 'birthday_month',
    6: 'birthday_year',
}

export const REQUEST_TYPE_OPTIONS = [
    {
        text: 'Cập nhật trạng thái đơn',
        value: 101,
        department: 1,
    },
    {
        text: 'Kiểm tra đóng gói',
        value: 201,
        department: 2,
    },
    {
        text: 'Kiểm tra nhập hoàn',
        value: 202,
        department: 3,
    },
    {
        text: 'Giục giao hàng',
        value: 301,
        department: 3,
    },
    {
        text: 'Sửa tiền thu hộ',
        value: 302,
        department: 3,
    },
    {
        text: 'Huỷ giao hàng',
        value: 303,
        department: 3,
    },
    {
        text: 'Hẹn giao hàng',
        value: 304,
        department: 3,
    },
    {
        text: 'Sửa thông tin liên hệ',
        value: 305,
        department: 3,
    },
    {
        text: 'Kiểm tra đối soát',
        value: 401,
        department: 4,
    },
    {
        text: 'Khác',
        value: 0,
        department: null,
    },
]

export const REQUEST_DEPARTMENT_OTPIONS = [
    {
        text: 'Hệ thống',
        value: 1,
    },
    {
        text: 'Kho',
        value: 2,
    },
    {
        text: 'Vận chuyển',
        value: 3,
    },
    {
        text: 'Kế toán',
        value: 4,
    },
]

export const ORDER_REQUEST_DEFAULT = {
    tracking_id: null,
    type: null,
    incharge_department: null,
    content: null,
    cash_on_delivery: '',
    request_new_cod: '',
}

export const SSC_DELIVERY_CONTRACT = -1
export const TVC_DELIVERY_CONTRACT = -2

export const GOODS_ISSUE_STATE_NAME = {
    0: 'Hủy đơn',
    20: 'Thiếu hàng',
    35: 'Chờ mã vận chuyển',
    40: 'Đã tiếp nhận',
    60: 'Đang xử lý',
    100: 'Đã pick',
    160: 'Đã đóng gói',
    200: 'Đã bàn giao vận chuyển',
    220: 'Đã nhận hoàn',
    240: 'Đã nhập hoàn',
}

export const BARCODE_MULTIPACK = 'SSC-MULTIPACK-SSC'

export const STAMP_SIZE_OPTIONS = [
    {
        value: '200x100',
        text: '200x100',
        per_page: 1,
    },
    {
        value: '100x25',
        text: '100x25',
        per_page: 1,
    },
    {
        value: '100x50',
        text: '50x50x2',
        per_page: 2,
    },
    {
        value: '100x20',
        text: '50x20x2',
        per_page: 2,
    },
    {
        value: '90x15',
        text: '30x15x3',
        per_page: 3,
    },
    {
        value: '90x15_horizontal',
        text: '30x15x3 - Ngang',
        per_page: 3,
    },
    {
        value: '90x20',
        text: '30x20x3',
        per_page: 3,
    },
    {
        value: '105x22',
        text: '35x22x3',
        per_page: 3,
    },
]

export const NUMBER_OPTIONS = {
    length: 11,
    precision: 0,
}

export const MONEY_OPTIONS = {
    length: 11,
    precision: 0,
    suffix: "",
}

export const GRAM_OPTIONS = {
    length: 11,
    precision: 0,
    suffix: "g",
}

export const zoneToIndexing = (zone) => {
    const zones = {
        '': 0,
        null: 0,
        'A': 1000000000,
        'B': 2000000000,
        'C': 3000000000,
        'D': 4000000000,
        'E': 5000000000,
        'F': 6000000000,
        'G': 7000000000,
        'H': 8000000000,
        'I': 9000000000,
        'J': 10000000000,
        'K': 11000000000,
        'L': 12000000000,
        'M': 13000000000,
        'N': 14000000000,
        'O': 15000000000,
        'P': 16000000000,
        'Q': 17000000000,
        'R': 18000000000,
        'S': 19000000000,
        'T': 20000000000,
        'U': 21000000000,
        'V': 22000000000,
        'W': 23000000000,
        'X': 24000000000,
        'Y': 25000000000,
        'Z': 26000000000,
    }
    return zones[zone]
}

export const positionToIndexing = (position, type) => {
    const positionIndexing = 1000000
    if (position === 1) {
        return positionIndexing
    } else {
        const isEven = position % 2 === 0
        if (type === 1) {
            return positionIndexing * (isEven ? position : (position - 1))
        } else {
            return positionIndexing * (isEven ? (position - 1) : position)
        }

    }
}

export const levelToIndexing = (level) => {
    const levels = {
        'A': 1,
        'B': 2,
        'C': 3,
        'D': 4,
        'E': 5,
        'F': 6,
        'G': 7,
        'H': 8,
        'I': 9,
        'J': 10,
        'K': 11,
        'L': 12,
        'M': 13,
        'N': 14,
        'O': 15,
        'P': 16,
        'Q': 17,
        'R': 18,
        'S': 19,
        'T': 20,
        'U': 21,
        'V': 22,
        'W': 23,
        'X': 24,
        'Y': 25,
        'Z': 26,
    }
    return levels[level]
}

export const cellToIndexing = (cell) => {
    return cell * 1000
}

export const cellPositionToIndexing = (position, type) => {
    const isEven = position % 2 === 0
    if (type === 1) {
        return isEven ? 0 : 100
    } else {
        return isEven ? 100 : 0
    }
}

export const calIndexing = (zone, position, level, cell, type) => {
    return zoneToIndexing(zone) + positionToIndexing(position, type) + levelToIndexing(level) + cellToIndexing(cell) + cellPositionToIndexing(position, type)
}


export const WARNING_TYPES = [
    {
        text: 'Sản phẩm',
        value: 1,
    },
    {
        text: 'Đơn hàng',
        value: 2,
    }
]

export const ITEM_TYPES = [
    {
        text: 'Sản phẩm',
        value: 1,
    },
]

export const WARNING_WARNING_TYPES = [
    {
        text: 'Làm muộn',
        value: 1,
    },
    {
        text: 'Nghỉ sớm',
        value: 2,
    },
    {
        text: 'Nghỉ trong ca',
        value: 3,
    },
]

export const WARNING_WORK_TYPES = [
    {
        text: 'Nhập kho',
        value: 1,
    },
    {
        text: 'Lên kệ',
        value: 2,
    },
    {
        text: 'Pickup',
        value: 3,
    },
    {
        text: 'Đóng gói',
        value: 4,
    },
    {
        text: 'BGVC',
        value: 5,
    },
    {
        text: 'Nhận hoàn',
        value: 6,
    },
    {
        text: 'Nhập hoàn',
        value: 7,
    },
    {
        text: 'LK. Hàng hoàn',
        value: 8,
    },
]

export const WARNING_CONFIG_DEFAULT = [
    {
        work_type: 1,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 2,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 3,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 4,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 5,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 2,
    },
    {
        work_type: 6,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 7,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
    {
        work_type: 8,
        wait_time: null,
        wait_quantity: null,
        process_quantity: null,
        type: 1,
    },
]

export const PACKAGING_TYPES = [
    'carton', 'pe', 'kraft_bubble', 'no_pack', 'bubble', 'non_woven_fabric', 'labels', 'ink', 'tape', 'paper', 'ribbon'
]

export const PACKAGING_UNITS = ['cuộn', 'bó', 'hộp', 'tập', 'cây']

export const ZALO_ZNS_TYPES = [1, 2, 3, 4, 5, 6, 7]

export const ZALO_SENT_STATUS_OPTIONS = [
    { text: 'Gửi thành công', value: 0 },
    { text: 'Xảy ra lỗi không xác định, vui lòng thử lại sau', value: -100 },
    { text: 'Ứng dụng gửi ZNS', value: -101 },
    { text: 'Ứng dụng gửi ZNS', value: -102 },
    { text: 'Ứng dụng chưa được kích hoạt', value: -103 },
    { text: 'Secret key của ứng dụng không hợp lệ', value: -104 },
    { text: 'Ứng dụng gửi ZNS chưa đươc liên kết với OA nào', value: -105 },
    { text: 'Phương thức không được hỗ trợ', value: -106 },
    { text: 'ID thông báo không hợp lệ', value: -107 },
    { text: 'Số điện thoại không hợp lệ', value: -108 },
    { text: 'ID mẫu ZNS không hợp lệ', value: -109 },
    { text: 'Phiên bản Zalo app không được hỗ trợ. Người dùng cần cập nhật phiên bản mới nhất', value: -110 },
    { text: 'Mẫu ZNS không có dữ liệu', value: -111 },
    { text: 'Nội dung mẫu ZNS không hợp lệ', value: -112 },
    { text: 'Không thể tạo QR code, vui lòng kiểm tra lại', value: -1123 },
    { text: 'Button không hợp lệ', value: -113 },
    { text: 'Người dùng không nhận được ZNS vì các lý do: Trạng thái tài khoản, Tùy chọn nhận ZNS, Sử dụng Zalo phiên bản cũ, hoặc các lỗi nội bộ khác', value: -114 },
    { text: 'Tài khoản ZNS không đủ số dư', value: -115 },
    { text: 'Nội dung không hợp lệ', value: -116 },
    { text: 'OA hoặc ứng dụng gửi ZNS chưa được cấp quyền sử dụng mẫu ZNS này', value: -117 },
    { text: 'Tài khoản Zalo không tồn tại hoặc đã bị vô hiệu hoá', value: -118 },
    { text: 'Tài khoản không thể nhận ZNS', value: -119 },
    { text: 'OA chưa được cấp quyền sử dụng tính năng này', value: -120 },
    { text: 'Mẫu ZNS không có nội dung', value: -121 },
    { text: 'Body request không đúng định dạng JSON', value: -122 },
    { text: 'Giải mã nội dung thông báo RSA thất bại', value: -123 },
    { text: 'Mã truy cập không hợp lệ', value: -124 },
    { text: 'ID Official Account không hợp lệ', value: -125 },
    { text: 'Ví (development mode) không đủ số dư', value: -126 },
    { text: 'Template test chỉ có thể được gửi cho quản trị viên', value: -127 },
    { text: 'Mã encoding key không tồn tại', value: -128 },
    { text: 'Không thể tạo RSA key, vui lòng thử lại sau', value: -129 },
    { text: 'N ội dung mẫu ZNS vượt quá giới hạn kí tự', value: -130 },
    { text: 'Mẫu ZNS chưa được phê duyệt', value: -131 },
    { text: 'Tham số không hợp lệ', value: -132 },
    { text: 'Mẫu ZNS này không được phép gửi vào ban đêm (từ 22h-6h)', value: -133 },
    { text: 'Người dùng chưa phản hồi gợi ý nhận ZNS từ OA', value: -134 },
    { text: 'OA chưa có quyền gửi ZNS (chưa được xác thực, đang sử dụng gói miễn phí)', value: -135 },
    { text: 'Cần kết nối với ZCA để sử dụng tính năng này', value: -136 },
    { text: 'Thanh toán ZCA thất bại (ví không đủ số dư,…)', value: -137 },
    { text: 'Ứng dụng gửi ZNS chưa có quyền sử dụng tính năng này', value: -138 },
    { text: 'Người dùng từ chối nhận loại ZNS này', value: -139 },
    { text: 'OA chưa được cấp quyền gửi ZNS hậu mãi cho người dùng này', value: -140 },
    { text: 'Người dùng từ chối nhận ZNS từ Official Account', value: -141 },
    { text: 'RSA key không tồn tại, vui lòng gọi API tạo RSA key', value: -142 },
    { text: 'RSA key đã tồn tại, vui lòng gọi API lấy RSA key', value: -143 },
    { text: 'OA đã vượt giới hạn gửi ZNS trong ngày', value: -144 },
    { text: 'OA không được phép gửi loại nội dung ZNS này', value: -145 },
    { text: 'Mẫu ZNS này đã bị vô hiệu hóa do chất lượng gửi thấp', value: -146 },
    { text: 'Mẫu ZNS đã vượt giới hạn gửi trong ngày', value: -147 },
    { text: 'Không tìm thấy ZNS journey token', value: -148 },
    { text: 'ZNS journey token không hợp lệ', value: -149 },
    { text: 'ZNS journey token đã hết hạn', value: -150 },
    { text: 'Không phải mẫu ZNS E2EE', value: -151 },
    { text: 'Lấy E2EE key thất bại', value: -152 },
]
export const ZALO_DELIVERY_STATUS_OPTIONS = [
    {
        text: 'Đã nhận',
        value: 1
    },
    {
        text: 'Chưa nhận',
        value: 0
    },
]

export const INVENTORY_DEFAULT = {
    id_warehouse: null,
    start_date: null,
    end_date: null,
    name: null,
    classify: null,
    id_customer: null,
    type: null,
    skus: '',
}

export const INVENTORY_CLASSIFY_OPTIONS = [
    {
        text: 'Cả kho',
        value: 1
    },
    {
        text: 'Theo khách',
        value: 2
    },
]

export const INVENTORY_TYPE_OPTIONS = [
    {
        text: 'Mọi SKU',
        value: 1
    },
    {
        text: 'Danh sách',
        value: 2
    },
]

export const INVENTORY_STATUS_OPTIONS = [
    {
        text: 'Hủy',
        value: 0
    },
    {
        text: 'Tạo',
        value: 1
    },
    {
        text: 'Chờ kiểm',
        value: 2
    },
    {
        text: 'Đang kiểm',
        value: 3
    },
    {
        text: 'Đóng',
        value: 4
    },
]

export const VAT_OPTIONS = [
    {
        text: 'Không chịu thuế',
        value: -1
    },
    {
        text: '0%',
        value: 0
    },
    {
        text: '5%',
        value: 5
    },
    {
        text: '8%',
        value: 8
    },
    {
        text: '10%',
        value: 10
    },
]

export const LICENSE_PRICE_CLASSIFY_OPTIONS = [
    {
        text: 'Phần mềm',
        value: 1
    },
    {
        text: 'Phụ trợ',
        value: 2
    },
]

export const LICENSE_PRICE_TYPE_OPTIONS = [
    {
        text: 'Khách',
        value: 1
    },
    {
        text: 'Kho',
        value: 2
    },
    {
        text: 'POS',
        value: 3
    },
]

export const LICENSE_PRICE_UNIT_OPTIONS = [
    {
        text: 'Kho / Năm',
        value: 'kho_nam'
    },
    {
        text: 'Tài khoản / Năm',
        value: 'tai_khoan_nam'
    },
    {
        text: 'Cửa hàng / Năm',
        value: 'cua_hang_nam'
    },
    {
        text: 'Tài khoản / Tháng',
        value: 'tai_khoan_thang'
    },
    {
        text: '01 tháng',
        value: '01_thang'
    },
    {
        text: '03 tháng',
        value: '03_thang'
    },
    {
        text: '06 tháng',
        value: '06_thang'
    },
    {
        text: '01 ngày',
        value: '01_ngay'
    },
    {
        text: '07 ngày',
        value: '07_ngay'
    },
    {
        text: '15 ngày',
        value: '15_ngay'
    },
    {
        text: 'Đơn hàng',
        value: 'don_hang'
    },
    {
        text: 'Hóa đơn',
        value: 'hoa_don'
    },
    {
        text: 'Nhân viên',
        value: 'nhan_vien'
    },
    {
        text: 'Bộ chính sách',
        value: 'bo_chinh_sach'
    },
    {
        text: 'Vòng đời tài khoản',
        value: 'vong_doi_tai_khoan'
    },
]

export const LICENSE_PRICE_STATUS_OPTIONS = [
    {
        text: 'Hoạt động',
        value: 1
    },
    {
        text: 'Ngừng hoạt động',
        value: 0
    },
]

export const LICENSE_ASSIGN_STATUS_OPTIONS = [
    {
        text: 'Không thời hạn',
        value: 0
    },
    {
        text: 'Sắp diễn ra',
        value: 1
    },
    {
        text: 'Đang diễn ra',
        value: 2
    },
    {
        text: 'Kết thúc',
        value: 3
    },
    {
        text: 'Kết thúc sớm',
        value: 4
    },
    {
        text: 'Hủy',
        value: 5
    },
]

export const LICENSE_UNIT_LIMITS = [
    'kho_nam',
    'cua_hang_nam',
    '01_thang',
    '03_thang',
    '06_thang',
    '01_ngay',
    '07_ngay',
    '15_ngay',
    'tai_khoan_thang',
    'tai_khoan_nam',
]

export const LICENSE_UNIT_LIMIT_DETAIL = {
    'kho_nam': {
        add_amount: 1,
        add_type: 'year',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    'tai_khoan_nam': {
        add_amount: 1,
        add_type: 'year',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    'cua_hang_nam': {
        add_amount: 1,
        add_type: 'year',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    'tai_khoan_thang': {
        add_amount: 1,
        add_type: 'month',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    '01_thang': {
        add_amount: 1,
        add_type: 'month',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    '03_thang': {
        add_amount: 3,
        add_type: 'months',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    '06_thang': {
        add_amount: 6,
        add_type: 'months',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    '01_ngay': {
        add_amount: 1,
        add_type: 'day',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    '07_ngay': {
        add_amount: 7,
        add_type: 'days',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
    '15_ngay': {
        add_amount: 15,
        add_type: 'days',
        sub_amount: 1,
        sub_type: 'day',
        renew_amount: 1,
        renew_type: 'day',
    },
}

export const INVENTORY_DETAIL_STATUS_OPTIONS = [
    {
        text: 'Chưa kiểm',
        value: 1
    },
    {
        text: 'Đang kiểm',
        value: 2
    },
    {
        text: 'Đã kiểm',
        value: 3
    },
]

export const PICKUP_COMMAND_CONFIG = [
    {
        key: 0,
        maxItemPerBasket: 50,
        display: "01 sp",
        type: "pickup",
    },
    {
        key: 1,
        maxItemPerBasket: 20,
        display: "≤32 cm || ≥ 02 sp || 01 sku",
        type: "pickup",
    },
    {
        key: 2,
        maxItemPerBasket: 20,
        display: "≤32 cm || ≥ 02 sp || 02 sku",
        type: "pickup",
    },
    {
        key: 3,
        maxItemPerBasket: 20,
        display: "≤32 cm || ≤ 20 sp || 03 sku (↑)",
        type: "pickup",
    },
    {
        key: 4,
        maxItemPerBasket: 20,
        display: ">32 cm || ≥ 02 sp || 01 sku",
        type: "pickup",
    },
    {
        key: 5,
        maxItemPerBasket: 20,
        display: ">32 cm || ≥ 02 sp || 02 sku",
        type: "pickup",
    },
    {
        key: 6,
        maxItemPerBasket: 20,
        display: ">32 cm || ≤ 20 sp || 03 sku (↑)",
        type: "pickup",
    },
    {
        key: 100,
        minItemPerOrder: 21,
        display: "Đơn nhiều sp, SL. Từ",
        type: "pickup",
    },
]

export const PICKUP_BASKET_CONFIG = [
    {
        key: 0,
        basket: 1,
        display: "SL. Sọt: 01 sp",
        type: "pickup_basket",
    },
]


export const PICKUP_COMMAND_CONFIG_2 = [
    {
        key: 0,
        maxOrderPerBasket: 50,
        display: "01 sp",
        type: "pickup",
    },
    {
        key: 1,
        maxOrderPerBasket: 25,
        display: "≥ 02 sp || 01 sku",
        type: "pickup",
    },
    {
        key: 2,
        maxOrderPerBasket: 25,
        display: "02 sku",
        type: "pickup",
    },
    {
        key: 3,
        maxOrderPerBasket: 17,
        display: "03 sku",
        type: "pickup",
    },
    {
        key: 4,
        maxOrderPerBasket: 12,
        display: "04 sku",
        type: "pickup",
    },
    {
        key: 5,
        maxOrderPerBasket: 10,
        display: "05 sku",
        type: "pickup",
    },
    {
        key: 6,
        maxOrderPerBasket: 8,
        display: "06 sku",
        type: "pickup",
    },
]

export const ORDER_STAMP_CONFIG = [
    {
        key: "create_stamp_shopee",
        value: "custom",
        display: "Shopee",
        type: "order_stamp",
    },
]

export const ORDER_STAMP_FOOTER = [
    {
        key: "order_shopee_footer",
        value: "Thiết kế, Quản lý, Tối ưu vận hành Đa kho & Chuỗi cửa hàng quy mô công nghiệp <br> Email: hello@ssc.eco",
        display: "Shopee",
        type: "order_stamp_footer",
    },
    {
        key: "order_other_footer",
        value: "Thiết kế, Quản lý, Tối ưu vận hành Đa kho & Chuỗi cửa hàng quy mô công nghiệp <br> Email: hello@ssc.eco",
        display: "Tem ngoài sàn",
        type: "order_stamp_footer",
    },
]

export const PACKAGE_CONFIG = [
    {
        key: "stamp_shopee",
        size: "100x80",
        display: "Tem Shopee",
        type: "package",
    },
    {
        key: "stamp_lazada",
        size: "100x80",
        display: "Tem Lazada",
        type: "package",
    },
    {
        key: "stamp_tiktok",
        size: "100x80",
        display: "Tem Tiktok",
        type: "package",
    },
    {
        key: "stamp_other",
        size: "100x80",
        display: "Tem ngoài sàn",
        type: "package",
    },
    {
        key: "stamp_missing",
        size: "100x80",
        display: "Tem báo thiếu hàng",
        type: "package",
    },
]

export const RECEIPT_CONFIG = [
    {
        key: "receipt_quantity",
        quantity: 200,
        display: "Nhập kho",
        type: "receipt",
    },
]


export const DELIVERY_CONFIG = [
    {
        key: "validate_delivery_status",
        value: 1,
        display: "Chặn trạng thái cuối",
        type: "delivery",
    },
]


export const ZNS_ACTION_TYPES = [
    /* {
        text: i18n.t('labels.zalo_template_action_type_1'),
        value: 1
    },
    {
        text: i18n.t('labels.zalo_template_action_type_2'),
        value: 2
    },
    {
        text: i18n.t('labels.zalo_template_action_type_3'),
        value: 3
    }, */
    {
        text: "OTP",
        value: 101
    },
]

export const DC_CLASSIFY_OPTIONS = [
    {
        text: "Kho TMĐT",
        value: 1,
    },
    {
        text: "Kho DC",
        value: 2,
    },
    {
        text: "Nhà phân phối",
        value: 3,
    },
]