import Vue from 'vue'
import VueRouter from 'vue-router'
import LayoutMain from '../views/layout/Main.vue'
import auth from '../middleware/auth'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: LayoutMain,
        meta: {
            permissions: ['super_admin'],
            middleware: [auth],
            pageName: 'SSC',
            title: 'SSC',
        },
        children: [
            {
                path: '/',
                name: 'Dashboard',
                component: () => import('../views/pages/Dashboard.vue'),
                meta: {
                    permissions: [],
                    middleware: [auth],
                    pageName: 'Thống kê nhanh',
                    title: 'Thống kê nhanh',
                },
            },
            {
                path: '/customers',
                name: 'Customer',
                component: () => import('../views/pages/customers/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'QL. Khách hàng',
                    title: 'QL. Khách hàng',
                },
            },

            {
                path: '/config-warehouse',
                name: 'ConfigWarehouse',
                component: () => import('../views/pages/config/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Cấu hình kho',
                    title: 'Cấu hình kho',
                },
            },

            {
                path: '/config-delivery',
                name: 'ConfigDelivery',
                component: () => import('../views/pages/config/Delivery.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Cấu hình vận chuyển',
                    title: 'Cấu hình vận chuyển',
                },
            },

            {
                path: '/generate-pdf-stamp',
                name: 'GeneratePdfStamp',
                component: () => import('../views/pages/generate_pdf/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Tạo tem',
                    title: 'Tạo tem',
                },
            },

            {
                path: '/push-notification',
                name: 'PushNotification',
                component: () => import('../views/pages/notification/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Thông báo',
                    title: 'Thông báo',
                },
            },

            {
                path: '/administration',
                name: 'Administration',
                component: () => import('../views/pages/administration/Index.vue'),
                meta: {
                    permissions: ['supper_admin'],
                    middleware: [auth],
                    pageName: 'Quản trị',
                    title: 'Quản trị',
                },
            },

            {
                path: '/license',
                name: 'License',
                component: () => import('../views/pages/license/Index.vue'),
                meta: {
                    permissions: ['supper_admin'],
                    middleware: [auth],
                    pageName: 'Bản quyền',
                    title: 'Bản quyền',
                },
            },

            {
                path: '/employee-list',
                name: 'EmployeeList',
                component: () => import('../views/pages/employee/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'QL. Nhân viên',
                    title: 'QL. Nhân viên',
                },
            },
            {
                path: '/employee-warning',
                name: 'EmployeeWarning',
                component: () => import('../views/pages/employee/Warning.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Cảnh báo công việc',
                    title: 'Cảnh báo công việc',
                },
            },
            {
                path: '/packaging',
                name: 'Packaging',
                component: () => import('../views/pages/packaging/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'QL. Vật tư',
                    title: 'QL. Vật tư',
                },
            },
            {
                path: '/zalo-config',
                name: 'ZaloConfig',
                component: () => import('../views/pages/zns/ZaloConfig.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Zalo',
                    title: 'Zalo'
                },
            },
            {
                path: '/zalo-oauth',
                name: 'ZaloOAuth',
                component: () => import('../views/pages/zns/ZaloOauth.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'ZaloOAuth',
                    title: 'ZaloOAuth'
                },
            },
            {
                path: '/zns',
                name: 'Zns',
                component: () => import('../views/pages/zns/Index'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Lịch sử ZNS',
                    title: 'Lịch sử ZNS',
                },
            },
            {
                path: '/uid',
                name: 'Uid',
                component: () => import('../views/pages/goods/Uid'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'UID',
                    title: 'UID',
                },
            },
            {
                path: '/dc-uid',
                name: 'DcUid',
                component: () => import('../views/pages/goods/DcUid'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Distribution Center - UID',
                    title: 'Distribution Center - UID',
                },
            },
            {
                path: '/goods-issue/detail',
                name: 'GoodsIssueDetail',
                component: () => import('../views/pages/goods_issue/Detail.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Chi tiết đơn online',
                    title: 'Chi tiết đơn online',
                },
            },
            {
                path: '/pos-order/detail',
                name: 'PosOrderDetail',
                component: () => import('../views/pages/pos_order/Detail.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Chi tiết đơn trực tiếp',
                    title: 'Chi tiết đơn trực tiếp',
                },
            },
            {
                path: '/goods-receipt/list',
                name: 'GoodsReceipt',
                component: () => import('../views/pages/goods_receipt/Index.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'QL. Nhập',
                    title: 'QL. Nhập'
                },
            },
            {
                path: '/goods/report',
                name: 'GoodsReport',
                component: () => import('../views/pages/goods/Report.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Tốc độ vơi hàng',
                    title: 'Tốc độ vơi hàng'
                },
            },
            {
                path: '/e-market/re-sync-order',
                name: 'ReSyncOrderEMarket',
                component: () => import('../views/pages/e_market/ReSyncOrder.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'ĐB. Sàn',
                    title: 'ĐB. Sàn'
                },
            },
            {
                path: '/order/merge-stamp',
                name: 'MergeOrderStamp',
                component: () => import('../views/pages/goods_issue/MergeStamp.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'In tem hàng loạt',
                    title: 'In tem hàng loạt'
                },
            },
            {
                path: '/goods/inventory',
                name: 'Inventory',
                component: () => import('../views/pages/goods/Inventory.vue'),
                meta: {
                    permissions: ['super_admin'],
                    middleware: [auth],
                    pageName: 'Kiểm kê',
                    title: 'Kiểm kê'
                },
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/pages/Login.vue'),
    },
    {
        path: '/403',
        name: 'Error403',
        component: () => import('../views/pages/errors/403.vue'),
    }
]


const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        context.next(...parameters);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

router.beforeEach((to, from, next) => {
    if (to.meta && to.meta.middleware) {
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);
        return middleware[0]({ ...context, next: nextMiddleware });
    }

    return next();
});

router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || process.env.VUE_APP_TITLE;
    });
});

export default router
