<template>
  <v-app>
    <v-app-bar app color="white">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="pl-0">{{ $route.meta.pageName }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <span class="hidden-xs-only">{{ employee.full_name }}</span>

      <v-menu
        offset-y
        bottom
        left
        :close-on-content-click="false"
        v-model="notifyMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            small
            v-bind="attrs"
            v-on="on"
            class="mx-2"
            color="white"
          >
            <v-badge
              bordered
              overlap
              color="error"
              :content="notifyCount"
              v-if="notifyCount > 0"
            >
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
            <v-icon v-else>mdi-bell-outline</v-icon>
          </v-btn>
        </template>
        <v-card
          class="mx-auto"
          max-width="350"
          min-width="300"
          max-height="500"
        >
          <NotifyList v-if="notifyMenu" @onNotifyChange="onNotifyChange" />
        </v-card>
      </v-menu>

      <!--<v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn depressed v-bind="attrs" v-on="on" small color="white" class="px-1">
                  <v-icon>mdi-translate</v-icon>
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item class="cursor-pointer" @click="changeLanguage('vi')" :class="{'primary white--text': $i18n.locale === 'vi'}">
                  <v-list-item-title>Tiếng Việt</v-list-item-title>
                </v-list-item>
                <v-list-item class="cursor-pointer" @click="changeLanguage('en')" :class="{'primary white--text': $i18n.locale === 'en'}">
                  <v-list-item-title>English</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>-->

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            v-bind="attrs"
            v-on="on"
            small
            color="white"
            class="px-1"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item v-if="isMobileAgent()">
            <v-list-item-title>{{ employee.full_name }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showDialogChangePassword">
            <v-list-item-title>{{
              $t("labels.change_password")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="apto" @click="backToAdmin">
            <v-list-item-title>Quay lại tài khoản Admin</v-list-item-title>
          </v-list-item>
          <v-list-item v-else @click="logout">
            <v-list-item-title>{{ $t("labels.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-toolbar elevation="4" light>
        <a href="/">
          <v-img
            height="35"
            contain
            :src="require('@/assets/common/logo.png')"
          ></v-img>
        </a>
      </v-toolbar>

      <v-divider></v-divider>

      <template v-if="rows.length > 0">
        <v-divider></v-divider>

        <v-list dense>
          <div v-for="item in rows" :key="item.title">
            <template v-if="checkMenuItem(item)"
              ><!--  -->
              <!-- item group -->
              <v-list-group v-if="item.children">
                <template v-slot:prependIcon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </template>
                <template v-slot:activator>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </template>

                <!-- item group child -->
                <div v-for="child in item.children" :key="child.title">
                  <v-list-item
                    v-if="checkMenuItem(child)"
                    :to="{ name: child.route }"
                    :disabled="!checkMenuItem(child) || child.disabled"
                    ><!--  -->
                    <v-list-item-icon>
                      <v-icon v-if="child.icon">{{ child.icon }}</v-icon>
                      <img
                        v-if="child.img"
                        :src="child.img"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ child.title }}
                      </v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-icon>
                      <v-icon v-if="child.appendIcon">
                        {{ child.appendIcon }}
                      </v-icon>
                      <img
                        v-if="child.appendImg"
                        :src="child.appendImg"
                        width="24px"
                        alt=""
                      />
                    </v-list-item-icon>
                  </v-list-item>
                </div>
              </v-list-group>
              <!-- end item group -->

              <!-- item -->
              <v-list-item
                v-else
                link
                :to="{ name: item.route }"
                @click="checkRoute(item.route)"
                :disabled="!checkMenuItem(item) || item.disabled"
              >
                <v-list-item-icon>
                  <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                  <img v-if="item.img" :src="item.img" width="24px" alt="" />
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!-- end item -->
            </template>
          </div>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-dialog v-model="dialogChangePassword" persistent max-width="350px">
      <v-card v-if="dialogChangePassword">
        <v-card-title class="text-h5 mb-3">{{
          $t("labels.change_password")
        }}</v-card-title>

        <v-card-text>
          <v-text-field
            v-model="oldPassword"
            class="c-input-small mb-3"
            :label="$t('labels.old_password')"
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            autofocus
            dense
            outlined
            hide-details
          ></v-text-field>

          <v-text-field
            v-model="newPassword"
            class="c-input-small mb-3"
            :label="$t('labels.new_password')"
            :type="passwordShow ? 'text' : 'password'"
            :disabled="!oldPassword"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            autocomplete="off"
            @click:append="passwordShow = !passwordShow"
            autocapitalize="off"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="hideDialogChangePassword">
            {{ $t("labels.cancel") }}
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="changePassword"
            :disabled="!oldPassword || !newPassword || newPassword.length < 6"
          >
            {{ $t("labels.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- audio list -->
    <audio id="error_sound_player">
      <source type="audio/mpeg" src="@/assets/audio/error.wav" />
    </audio>
    <audio id="success_sound_player">
      <source type="audio/mpeg" src="@/assets/audio/success.wav" />
    </audio>

    <audio id="DonDaHuy_player">
      <source type="audio/mpeg" src="@/assets/audio/DonDaHuy.wav" />
    </audio>
    <audio id="DonDaQuet_player">
      <source type="audio/mpeg" src="@/assets/audio/DonDaQuet.wav" />
    </audio>
    <audio id="KhongCoQuyenXuLy_player">
      <source type="audio/mpeg" src="@/assets/audio/KhongCoQuyenXuLy.wav" />
    </audio>
    <audio id="MaDonKhongTonTai_player">
      <source type="audio/mpeg" src="@/assets/audio/MaDonKhongTonTai.wav" />
    </audio>
    <audio id="NhamVanChuyen_player">
      <source type="audio/mpeg" src="@/assets/audio/NhamVanChuyen.wav" />
    </audio>
    <audio id="NhamSotRoi_player">
      <source type="audio/mpeg" src="@/assets/audio/NhamSotRoi.wav" />
    </audio>
    <audio id="NhamDonRoi_player">
      <source type="audio/mpeg" src="@/assets/audio/NhamDonRoi.wav" />
    </audio>
    <audio id="TrangThaiKhongHopLe_player">
      <source type="audio/mpeg" src="@/assets/audio/TrangThaiKhongHopLe.wav" />
    </audio>
    <audio id="ChoBienBanBanGiao_player">
      <source type="audio/mpeg" src="@/assets/audio/ChoBienBanBanGiao.wav" />
    </audio>
  </v-app>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "App",

  components: {
    NotifyList: () => import("@/components/common/NotifyList"),
  },

  data: () => ({
    drawer: false,
    activeItem: "LayoutMain",
    items: [
      {
        title: "Thống kê nhanh",
        img: require("@/assets/sidebars/thongkenhanh.png"),
        route: "Dashboard",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Khách hàng",
        img: require("@/assets/sidebars/client.png"),
        route: "Customer",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "QL. Nhân viên",
        img: require("@/assets/sidebars/hr.png"),
        route: "EmployeeList",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Quản trị",
        img: require("@/assets/sidebars/quantri.png"),
        route: "Administration",
        active: false,
        permissions: ["supper_admin", "warehouse_admin"],
      },
      {
        title: "Kho",
        img: require("@/assets/sidebars/vanchuyen.png"),
        route: "ConfigWarehouse",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Vận chuyển",
        img: require("@/assets/sidebars/vanchuyen.png"),
        route: "ConfigDelivery",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Tạo tem",
        img: require("@/assets/sidebars/generate_pdf.png"),
        route: "GeneratePdfStamp",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Thông báo",
        img: require("@/assets/sidebars/push_notification.png"),
        route: "PushNotification",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Bản quyền",
        img: require("@/assets/sidebars/create_order_excel.png"),
        route: "License",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Cảnh báo công việc",
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        route: "EmployeeWarning",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "QL. Vật tư",
        img: require("@/assets/sidebars/supplies.png"),
        route: "Packaging",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Zalo",
        img: require("@/assets/sidebars/zalo.png"),
        route: "ZaloConfig",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Lịch sử ZNS",
        img: require("@/assets/sidebars/zns.png"),
        route: "Zns",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "UID",
        img: require("@/assets/sidebars/uid.png"),
        route: "Uid",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Distribution Center - UID",
        img: require("@/assets/sidebars/generate_pdf.png"),
        route: "DcUid",
        active: false,
        permissions: ["super_admin"],
        modules: [],
      },
      {
        title: "Chi tiết đơn online",
        img: require("@/assets/sidebars/quanlyxuatkho.png"),
        route: "GoodsIssueDetail",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Chi tiết đơn trực tiếp",
        img: require("@/assets/sidebars/quanlyxuatkho.png"),
        route: "PosOrderDetail",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "QL. Nhập",
        img: require("@/assets/sidebars/quanlynhapkho.png"),
        route: "GoodsReceipt",
        active: false,
        permissions: ["super_admin"],
      },
      {
        title: "Tốc độ vơi hàng",
        img: require("@/assets/sidebars/vanchuyen.png"),
        route: "GoodsReport",
        active: false,
        permissions: ["super_admin"],
        modules: [],
      },
      {
        title: "ĐB. Sàn",
        img: require("@/assets/sidebars/quanlynhapkho.png"),
        route: "ReSyncOrderEMarket",
        active: false,
        permissions: ["super_admin"],
        modules: [],
      },
      {
        title: "In tem hàng loạt",
        img: require("@/assets/sidebars/generate_pdf.png"),
        route: "MergeOrderStamp",
        active: false,
        permissions: ["super_admin"],
        modules: [],
      },
      {
        title: "Kiểm kê",
        img: require("@/assets/sidebars/pos_nhap_kiem.png"),
        route: "Inventory",
        active: false,
        permissions: ["super_admin"],
        modules: [],
      },
    ],
    employee: {},
    notifyCount: 0,
    notifyMenu: false,
    dialogChangePassword: false,
    oldPassword: null,
    newPassword: null,
    passwordShow: false,
    apto: null,
  }),

  computed: {
    rows() {
      const rows = [];
      this.items.forEach((item) => {
        if (this.checkMenuItem(item)) {
          rows.push(item);
        }
      });
      return rows;
    },
  },
  watch: {},
  mounted() {
    window.socket.on("auth", (data) => {
      console.log(data);
    });
    window.socket.emit("auth", window.me.auth_key, "employee");

    this.apto = localStorage.getItem("_apto");
    const { employee, notify } = window.me;
    this.employee = { ...employee };
    this.notifyCount = notify;
    // this.listenSocket()

    this.$root.$on("playErrorAudio", this.playErrorAudio);
    this.$root.$on("playSuccessAudio", this.playSuccessAudio);
    this.$root.$on("playAudioByName", this.playAudioByName);
  },
  beforeDestroy() {
    this.$root.$off("playErrorAudio", this.playErrorAudio);
    this.$root.$off("playSuccessAudio", this.playSuccessAudio);
    this.$root.$off("playAudioByName", this.playAudioByName);
  },
  methods: {
    showDialogChangePassword() {
      this.dialogChangePassword = true;
    },

    hideDialogChangePassword() {
      this.dialogChangePassword = false;
      this.oldPassword = null;
      this.newPassword = null;
      this.passwordShow = false;
    },
    async changePassword() {
      if (!this.oldPassword || !this.newPassword) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-password", {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        });
        this.hideDialogChangePassword();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    checkMenuItem(item) {
      return (
        (!item.permissions ||
          item.permissions.length === 0 ||
          this.checkPermission(item.permissions)) &&
        this.checkMenuMobile(item.mobileCheck) &&
        this.checkMenuDesktop(item.desktopCheck)
      );
    },
    async logout() {
      localStorage.removeItem("_apt");
      localStorage.removeItem("_apto");
      localStorage.removeItem("_pktb");
      window.location = process.env.VUE_APP_V2_HOME_PATH;
    },
    checkRoute(routeName) {
      if (this.$route.name === routeName) {
        window.location.reload();
      }
    },
    onNotifyChange(count) {
      this.notifyCount = count;
    },

    listenSocket() {
      window.socket.on("notification_push", (data) => {
        this.notifyCount += 1;
        this.$vToastify.warning(`Bạn có thông báo mới! "${data.title}"`);
      });
    },

    backToAdmin() {
      localStorage.setItem("_apt", this.apto);
      localStorage.removeItem("_apto");
      window.location.reload();
    },

    playErrorAudio() {
      document.getElementById("error_sound_player").play();
    },
    playSuccessAudio() {
      document.getElementById("success_sound_player").play();
    },
    playAudioByName(name) {
      document.getElementById(`${name}_player`).play();
    },
  },
};
</script>
